<template>
  <page-layout>
    <span slot="header-name">{{ $tfo('batch_dossier_upload') }}</span>

    <common-tabs slot="header-actions" v-model="objects" :items="objectTabs" :converter="$tfo" />

    <div slot="header-content-before-actions">
      <el-button @click="$router.push({ path: '/batch-upload/filter/limit=10' })">{{ $tfo('logs') }} </el-button>
    </div>

    <div slot="content">
      <el-form
        :size="$vars.sizes.form"
        :label-position="$vars.forms.label_position"
        :labelWidth="$vars.forms.label_width"
        ref="form"
        :model="item"
        :rules="rules"
        :name="$options.name + '-form'"
      >
        <el-form-item>
          <input name="input-files" style="display: none" ref="inputFiles" type="file" @change="selectFiles" multiple />
          <input name="input-dir" style="display: none" ref="inputDir" type="file" @change="selectFolder" multiple directory webkitdirectory mozdirectory />
          <el-button name="select-files-btn" @click="$refs.inputFiles.click()" :disabled="uploading">
            {{ $tfo(['common.select', 'common.files']) }}
          </el-button>
          <span class="mar-h-1">{{ $t('common.or') }}</span>
          <el-button name="select-dir-btn" @click="$refs.inputDir.click()" :disabled="uploading">
            {{ $tfo(['common.select', 'common.folder,, 1']) }}
          </el-button>
        </el-form-item>

        <el-form-item>
          <el-checkbox name="file-name" v-model="file_name" :disabled="uploading">
            {{ $tfo(['common.use', 'common.filename', 'common.as', 'common.name']) }}
          </el-checkbox>
        </el-form-item>
        <el-form-item :label="$tfo('name_prefix')">
          <el-input name="prefix-name" v-model="prefix_name" :disabled="uploading"></el-input>
        </el-form-item>
        <el-form-item :label="$tfo('name_postfix')">
          <el-input name="postfix-name" v-model="postfix_name" :disabled="uploading"></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox name="file-comment" v-model="file_comment" :disabled="uploading">
            {{ $tfo(['common.use', 'common.filename', 'common.as', 'common.comment']) }}
          </el-checkbox>
        </el-form-item>
        <el-form-item :label="$tfo('comment_prefix')">
          <el-input name="prefix-comment" v-model="prefix_comment" :disabled="uploading"></el-input>
        </el-form-item>
        <el-form-item :label="$tfo('comment_postfix')">
          <el-input name="postfix-comment" v-model="postfix_comment" :disabled="uploading"></el-input>
        </el-form-item>
        <el-form-item :label="$tfo('common.dossier_lists')" prop="dossier_lists">
          <el-select
            class="dossier-batch-uploader-form-field"
            name="dossier-lists"
            v-model="item.dossier_lists"
            :multiple="true"
            :disabled="uploading"
            :placeholder="$tf('common.select')"
          >
            <el-option
              v-for="listItem in $store.getters.dossierListsInDossier"
              :key="listItem.id"
              :value="listItem.id"
              :label="$filters.shortString(listItem.name)"
            >
              <dossier-list-option :item="listItem"></dossier-list-option>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$tfo('parallel,,1 | upload')">
          <el-radio-group name="parallel-requests" v-model="parallel_requests" :disabled="uploading">
            <el-radio-button v-for="item in parallel_requests_items" :label="item" :value="item" :key="item"></el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item :label="$tfo('group_ajv,,1 | photo')">
          <el-select class="dossier-batch-uploader-form-field" name="mf-selector" v-model="mf_selector" placeholder="">
            <el-option value="reject" :label="$tfo('reject')"></el-option>
            <el-option value="biggest" :label="$tf({ en: 'save | the | biggest,,1 | object', cn: 'save_biggest_face' })"></el-option>
            <el-option value="all" :label="$tf('all')"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            name="start-btn"
            type="primary"
            @click="startHandler"
            :disabled="$hasNoPermission('ffsecurity.batchupload_dossier') || !items.length || uploading"
          >
            {{ $tf('common.start') }}
          </el-button>
          <el-button name="stop-btn" type="info" :disabled="!uploading" :plain="true" @click="stopHandler">{{ $tf('common.stop') }} </el-button>
          <el-button name="cancel-btn" type="info" :plain="true" @click="cancelHandler">{{ $tf('common.back') }} </el-button>
        </el-form-item>
      </el-form>

      <div v-if="items.length">
        <el-progress
          class="mar-top--3"
          :text-inside="true"
          :stroke-width="18"
          :percentage="progress"
          :status="progress === 100 ? 'success' : null"
        ></el-progress>
        <el-form :inline="true">
          <el-form-item :label="$tf('common.selected') + ':'">
            {{ items.length }}
          </el-form-item>
          <el-form-item :label="$tf('common.uploaded') + ':'">
            {{ uploaded }}
          </el-form-item>
          <el-form-item :label="$tf('common.errors') + ':'">
            {{ failed }}
          </el-form-item>
          <el-form-item :label="$tf('common.page') + ':'">
            {{ Math.floor((uploaded + failed) / 10) + 1 }}
          </el-form-item>
        </el-form>

        <el-pagination
          class="pull-right mar-top--2 mar-bottom--1"
          ref="pagination"
          layout="prev, pager, next, jumper"
          :current-page.sync="currentPage"
          :page-size="10"
          :total="items.length"
        >
        </el-pagination>

        <el-table stripe :data="items.slice((currentPage - 1) * 10, currentPage * 10)" row-key="fileName">
          <el-table-column :label="$tf('common.name')">
            <template slot-scope="{ row }">
              <span>{{ row.file.name }}</span
              ><br />
            </template>
          </el-table-column>
          <el-table-column :label="$tf('common.image')" width="176">
            <template slot-scope="{ row }">
              <object-thumbnail
                :url="row.getSourcePhoto()"
                :frame="row.getSourcePhoto()"
                @preview="(v) => $store.dispatch('showImage', { src: v && v.src })"
              ></object-thumbnail>
            </template>
          </el-table-column>
          <el-table-column prop="file.size" :label="$tf('common.size')" width="120">
            <template slot-scope="{ row }">
              {{ row.file.size | bytesToKb }}
            </template>
          </el-table-column>
          <el-table-column prop="status" :label="$tf('common.status')" width="240">
            <template slot-scope="{ row }">
              <batch-item :item="row"></batch-item>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          class="pull-right mar-top--1 mar-bottom--2"
          ref="pagination"
          layout="prev, pager, next"
          :current-page.sync="currentPage"
          :page-size="10"
          :total="items.length"
        >
        </el-pagination>
      </div>
    </div>
  </page-layout>
</template>

<script>
import ObjectsInline from '../objects/inline.vue';
import ObjectThumbnail from '../objects/thumbnail.vue';
import BatchItem from './batch-item.vue';
import DossierListOption from '../dossier-lists/option.vue';
import PageLayout from '@/components/page/layout';

let baseRules = {
  dossier_lists: [{ required: true, type: 'array', min: 1, message: 'error.required.field', trigger: 'change' }]
};

const area = (bbox) => (bbox.bottom - bbox.top) * (bbox.right - bbox.left),
  biggest = (objects) => objects.reduce((acc, el) => (area(el.bbox) > area(acc.bbox) ? el : acc));

export default {
  components: {
    PageLayout,
    DossierListOption,
    BatchItem,
    ObjectThumbnail,
    ObjectsInline
  },
  name: 'dossier-batch',
  data: function () {
    return {
      objects: 'faces',
      batch_upload: null,
      batch_id: null,
      mf_selector: 'reject',
      upload_position: -1,
      parallel_requests: 5,
      parallel_requests_items: [2, 5, 10, 20],
      currentPage: 1,
      file_name: true,
      prefix_name: '',
      postfix_name: '',
      file_comment: false,
      prefix_comment: '',
      postfix_comment: '',
      item: {
        dossier_lists: []
      },
      items: [],
      progress: 0,
      uploaded: 0,
      failed: 0,
      uploading: false,
      rules: this.$applyRuleMessages(baseRules)
    };
  },
  computed: {
    objectTabs() {
      return this.$store.getters.enabledObjects.map((item) => {
        return { i18n: item, name: item };
      });
    },
    state() {
      return this.$store.state.dossiers;
    }
  },
  mounted() {
    this.setItems([]);
    this.$store.dispatch(this.$store.state.dossier_lists.Action.Get);
    this.$store.state.app.fileHandler = (files) => this.setItems(files, true);
  },
  beforeDestroy() {
    this.$store.state.app.fileHandler = null;
  },
  beforeRouteLeave(to, from, next) {
    if (this.uploading) {
      this.$confirm(this.$tf(['common.batch_upload_leave_confirm']), this.$tf(['common.warning']), {
        confirmButtonText: this.$tf(['common.ok']),
        cancelButtonText: this.$tf(['common.cancel']),
        type: 'warning'
      }).then(() => {
        this.stopHandler();
        this.setItems([]);
        next();
      });
    } else {
      next();
    }
  },
  methods: {
    async uploadNext() {
      this.upload_position = this.upload_position + 1;

      let items = this.items,
        position = Math.min(this.upload_position, items.length),
        stopped = position >= items.length || !this.uploading,
        progress = ((position * 100) / items.length) | 0;

      this.progress = progress;

      if (stopped) {
        this.uploading = false;
        return Promise.resolve(true);
      } else {
        const currentItem = items[position];
        let detectState,
          detectedItems,
          detected,
          message,
          detectionError = null;

        try {
          detectState = await this.$store.dispatch('getDetectObjectsState', { file: currentItem.file, objects: this.objects });
        } catch (e) {
          detectionError = e;
        }

        detectedItems = detectState?.items || [];

        if (detectionError) {
          currentItem.status = 'error';
          message = this.$tf('detection_error');
          currentItem.error = { desc: message };
        } else if (detectedItems.length === 0) {
          message = this.$tf('no | faces,,1 | in | file,,2') + ` ${currentItem.fileName}`;
          currentItem.status = 'error';
          currentItem.error = { desc: this.$tf('no_faces_on_photo') };
        } else if (this.mf_selector === 'all' || detectedItems.length === 1) {
          detected = detectedItems;
        } else if (this.mf_selector === 'reject' && detectedItems.length > 1) {
          message = this.$tf('more_than_one_object | in | file,,2') + ` ${currentItem.fileName}`;
          currentItem.status = 'error';
          currentItem.error = { desc: this.$tf('more_than_one_object') };
        } else if (this.mf_selector === 'biggest') {
          detected = [biggest(detectedItems)];
        }

        if (detected) {
          await this.uploadItemAll(currentItem, detected)
            .then(() => this.uploaded++)
            .catch(() => this.failed++);
        } else if (message) {
          this.failed++;
          this.$notify({
            type: 'warning',
            message
          });
        }

        return this.uploadNext();
      }
    },
    startHandler(e) {
      this.$refs.form.validate((r) => {
        if (!r) return;
        let name = this.$store.state.users.current.name + '-' + new Date().getTime() * 1000 + Math.floor(Math.random() * 1000),
          batchUploadPromise = this.batch_upload
            ? Promise.resolve(this.batch_upload)
            : this.$store.dispatch(this.$store.state.batch_upload.Action.Create, { name });

        batchUploadPromise
          .then((v) => {
            this.batch_upload = v;
            this.uploading = true;
            this.upload_position = -1;
            let processes = new Array(this.parallel_requests).fill(0);
            return Promise.all(processes.map((v) => this.uploadNext())).then((v) => {
              this.$notify({
                type: 'success',
                title: this.$tfo('batch_dossier_upload'),
                message: this.$tf('finished,,1')
              });
            });
          })
          .catch((e) => {
            this.$notify({
              duration: 0,
              message: this.$createElement('message-box', { props: { e: e } })
            });
          });
      });
    },
    stopHandler(e) {
      this.uploading = false;
    },
    selectFiles(e) {
      this.setItems(e.target.files);
      this.$refs.inputFiles.value = null;
    },
    selectFolder(e) {
      this.setItems(e.target.files);
      this.$refs.inputDir.value = null;
    },
    setItems(files, append) {
      this.uploaded = 0;
      this.failed = 0;
      this.progress = 0;

      const items = [].slice.call(files).map((v) => {
        return {
          fileName: v.name,
          file: v,
          sourcePhoto: null,
          getSourcePhoto: function () {
            return this.sourcePhoto ? this.sourcePhoto : (this.sourcePhoto = URL.createObjectURL(v));
          },
          status: ''
        };
      });

      this.items = append ? this.items.concat(items) : items;
    },
    getFileName(name) {
      let fileNameArray = (name || '').split('.');
      if (fileNameArray.length > 1) fileNameArray.pop();
      return fileNameArray.join('.');
    },
    getComment(file) {
      return this.prefix_comment + (this.file_comment ? this.getFileName(file.name) : '') + this.postfix_comment;
    },
    getName(file) {
      return this.prefix_name + (this.file_name ? this.getFileName(file.name) : '') + this.postfix_name;
    },
    async uploadItemAll(item, detected) {
      if (item.status === 'success' || item.status === 'dossier' || item.status === 'face') {
        return true;
      }

      item.status = 'dossier';
      item.dossiers = item.dossiers || {};

      const promises = detected.map(async (detect, i) => {
        const dossier = {
            name: this.getName(item.file) + (detected.length > 1 ? `_${i}` : ''),
            comment: this.getComment(item.file),
            dossier_lists: this.item.dossier_lists,
            active: true,
            upload_list: this.batch_upload.id
          },
          createdDossier = await this.$store.dispatch(this.$store.state.dossiers.Action.Create, dossier),
          object = {
            dossier: createdDossier.id,
            source_photo: item.file,
            detect,
            upload_list: this.batch_upload.id
          },
          formData = new FormData();

        formData.append('source_photo', object.source_photo);
        formData.append('dossier', object.dossier);
        formData.append('detect_id', object.detect.id);
        formData.append('upload_list', object.upload_list);

        return this.$store
          .dispatch('requestApi', { model: `objects/${this.objects}`, data: formData, method: 'post', timeout: 6e4 })
          .catch(() => this.$store.dispatch(this.$store.state.dossiers.Action.Delete, { id: createdDossier.id }));
      });
      return Promise.all(promises)
        .then((v) => {
          item.dossiers = v.map((d) => d.dossier);
          item.status = 'success';
          item.dossier_face = v;
          return true;
        })
        .catch((e) => {
          item.status = 'error';
          item.error = { desc: (e.response && e.response.data) || e.message };
          this.$notify({
            title: this.$tf('common.error'),
            message: this.$createElement('message-box', { props: { e: JSON.stringify(item.error) } }) // @todo refactoring message-box
          });
        });
    },
    cancelHandler(e) {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="stylus">
.dossier-batch-uploader-form-field {
  width: 15rem;
}
</style>
