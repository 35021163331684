<template>
  <div class="batch-item">
    <div>{{ getStatusText(item.status) }} <i class="el-icon-check" v-if="item.status === 'success'"></i></div>
    <div v-if="item.error" class="batch-item--error">{{ item.error && item.error.desc }}</div>
    <a v-for="dossier in dossiers" :href="`/#/dossiers/${dossier}/`" target="_blank">{{ $tf('common.dossier') }} {{ dossier }} <br /></a>
  </div>
</template>

<script>
export default {
  name: 'batch-item',
  props: ['item'],
  computed: {
    dossiers() {
      return this.item.dossiers.filter((v) => !!v);
    }
  },
  methods: {
    getStatusText(status) {
      switch (status) {
        case 'success':
          return '';
        case 'dossier':
        case 'face':
          return this.$tf('common.in_progress');
        case 'error':
          return this.$tf('common.error');
        default:
          return this.$tf('common.in_queue');
      }
    }
  }
};
</script>

<style>
.batch-item {
}

.batch-item--error {
  color: #999;
  font-size: 0.9rem;
}
</style>
